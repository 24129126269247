import React from 'react';
import { HomepageBanner, HomepageCallout } from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import { calloutLink } from './Homepage.module.scss';

import Carbon from './carbon.jpg';

const FirstLeftText = () => <p>DevOps</p>;

const FirstRightText = () => (
  <p>
  Adoption is THE measure of success, not knowing the top Best Practices: Git Version Control; Agile Methodology; 
  Continuous Integration/Continuous Deployment; Infrastructure as Code; Automated Testing; Implement Security 
  Best Practices; ...
  </p>
);

const SecondLeftText = () => <p>Portfolio Management</p>;

const SecondRightText = () => (
  <p>
  Avoid the biggest mistakes, such as: Lack of clear goals and strategy; Over-reliance on financial metrics;
  Focusing only on short-term gains; Lack of communication and collaboration; Failure to adapt to changing 
  market conditions; Not considering risk management
  </p>
);

const BannerText = () => <h1>... a boutique software and consulting company working with our partners to improve and optimize their 
  Portfolio Management and Development practices</h1>;

const customProps = {
  Banner: <HomepageBanner renderText={BannerText} image={Carbon} />,
  FirstCallout: (
    <HomepageCallout
      backgroundColor="#030303"
      color="white"
      leftText={FirstLeftText}
      rightText={FirstRightText}
    />
  ),
  SecondCallout: (
    <HomepageCallout
      leftText={SecondLeftText}
      rightText={SecondRightText}
      color="white"
      backgroundColor="#202020"
    />
  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
